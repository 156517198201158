import styled from 'styled-components';
import GatsbyImage from '../../components/gatsby-image';




const NomaWrapper = styled.div`
  width: 100%;
`;

export const PostPreview = styled.div`
 position: relative;
 width: 100%;
 padding-bottom: 40%;
 @media (max-width: 767px) {
  padding-bottom: 56.25%;
}

 
`;

interface bgProps {

  
  bg: string;

}

export const ImageWrapper = styled.div<bgProps>`
position: absolute;
padding: 40px;
display: flex;
align-items: center;
justify-content: center;
font-size: 30px;
width: 100%;
height: 100%;
background: url(${props => props.bg}) center;
background-size: cover;
span, p {
  color:#fff;
  text-align: center;
  font-family: 'Petrona', sans-serif;
}

a {
  color:#fff;
  font-weight: 500;
}

@media (max-width: 640px) {
  font-size: 24px;
}

`

export const BodyBold = styled.span`
color:#fff;
`
export const BodyParagraph = styled.span`
color:#fff;
text-align: center;
`

export const SliderImage = styled(GatsbyImage)`
height: 100%;
width: 100%;
object-fit: cover;

`

export default NomaWrapper;
