import React from 'react';
import { graphql } from 'gatsby';
import  { getSrc } from 'gatsby-plugin-image';
import Layout from '../containers/layout';
import Noma from '../containers/noma';
import SEO from '../components/seo';


const RentalsPage = ({  ...props }: any) => {
  const page = props.data.contentfulPage;
  const coverImage = getSrc(page.image) || "";
  
 
  return (
    <Layout>
       <SEO
        title={page.title}
        image={coverImage}
      />
     <Noma   
          title={page.title}
          image={page.image || null}
          body={page.body}></Noma>  
    </Layout>
  );
};

export default RentalsPage;

 export const pageQuery = graphql`
  query {
    contentfulPage(slug: {eq: "noma"}) {
      id
      title
      body {
        raw
      }
      image {
        gatsbyImageData(width: 1200, height: 630)
      }  
    }
  }
`;
 