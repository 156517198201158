import * as React from "react";
import NomaWrapper, {
  PostPreview,
  BodyBold,
  BodyParagraph,
  ImageWrapper,
} from "./style";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { getSrc } from "gatsby-plugin-image";

type NomaProps = {
  title: string;
  image?: any;
  body: any;
};

const Noma: React.FunctionComponent<NomaProps> = ({ title, image, body }) => {
  const bgImage = getSrc(image);
  const Bold: any = ({ children }) => (
    <BodyBold className="">{children}</BodyBold>
  );
  const Text: any = ({ children }) => (
    <BodyParagraph className="">{children}</BodyParagraph>
  );
  const options: any = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        return <></>;
      },
    },
  };

  return (
    <NomaWrapper>
      <PostPreview className="">
        <ImageWrapper bg={bgImage}>
          {body && renderRichText(body, options)}
        </ImageWrapper>
      </PostPreview>
    </NomaWrapper>
  );
};

export default Noma;
